import React from "react"

const DebtComponent = (props) => {

    let inputStyle ={
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "42%",
        padding: "0rem 0.3rem"
    }

    let errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        width: "90%"
    }

    const [name, setName] = React.useState(props.value?.name)
    const [amount, setAmount] = React.useState(props.value?.amount)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [amountError, setAmountError] = React.useState(props.value?.radioError)
    const [isDebt, setIsDebt] = React.useState(props.isDebt? props.isDebt : false)

    let errorMargin = "2.5rem"
    if( !(amountError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setName(() => props.value?.name ? props.value?.name: "")
        setAmount(() => props.value?.amount ? props.value?.amount: "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setAmountError(() => props.value?.amountError ? props.value?.amountError : "" )
        setIsDebt(() => props.isDebt ? props.isDebt: false)

    }, [props.value?.name, props.value?.amount, props.value?.nameError, props.value?.amountError, props.isDebt])

    React.useEffect(() => {
        props.updateFunc(
            {
                'name': name,
                'amount': amount
            }, props.index
        )
    }, [name, amount])

    const handleNameChange = async(event) => {
        setName( event.target.value)
    }

    const handleAmountChange = async (event) => {
        setAmount( event.target.value)
    }

    const handleDelete = async () => {
        props.deleteFunc( props.index)
    }

    return(
        <>
            <div style={{ display: "flex", justifyContent: "space-between", width: "90%", padding: "0.2rem 0.3rem", marginTop: "0.2rem"}}>
            <input type="Number" placeholder="Debt Amount"
                disabled={!isDebt}
                onChange={ handleAmountChange}
                value={amount}
                style={inputStyle}
                >
            </input>
            <input type="text" placeholder="Name"
                disabled={!isDebt}
                value={name}
                onChange={ handleNameChange}
                style={inputStyle}
                >
            </input>
            <img src={require("../assets/images/delete_icon.png")}
                style={{}}
                onClick={() => {
                    handleDelete()
                }}
            >
            </img>
            <div>
        </div>
            </div>
            <div style={{...errorStyle, marginLeft: "0.5rem"}}>
                { amountError ? amountError : ""}
                <span style={{marginLeft: errorMargin}}>
                    { nameError ? nameError : ""}
                </span>
            </div>

        </>

    )
}

export default DebtComponent