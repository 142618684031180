import React from "react"
import Header from "./header"
import WillComponent from "./willcomponent"
import RadioNameComp from "./radioAndNameComponent"
import NearAndDearComponent from "./nearAndDearsComponent"
import ChildComponent from "./childrenComponent"
import HttpService from "./backendAPIs/requestAPIs"
import { calculateShare, wakeUpBackend } from "./backendAPIs/backendAPIs"
import SpouseComp from "./spouseComponent"
import { json, useNavigate } from "react-router-dom"
import DebtComponent from "./debtComponent"
import CustomSnackbar from "./customSnackbar"
import { ValidateSubmit } from "../validations/homePageValidations"
import Loader from "./loader"

const MainCard = (props) => {
    const navigate = useNavigate()
    var inputBoxStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "100%",
        padding: "0rem 0.3rem"
    }
    var inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "40%",
        padding: "0rem 0.3rem"
    }

    var errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        // marginTop: "-0.45rem"
    }


    var [snackbarOpen, setOpen] = React.useState(false);
    var [snackBarText, setText] = React.useState("");
    var [snackbarSeverity, setSeverity] = React.useState("Info");

    var [isLoading, setIsLoading] = React.useState(false)

    const [name, setName] = React.useState(null)
    const [male, setMale] = React.useState(false)
    const [female, setFemale] = React.useState( false)
    const [nameError, setNameError] = React.useState(null)
    const [genderError, setGenderError] = React.useState(null)

    const [totalAmount, setTotalAmount] = React.useState(null)
    const [totalAmtError, setTotalAmtError] = React.useState(null)

    const [isDebt, setIsDebt] = React.useState(false)
    const [debtData, setDebtData] = React.useState([{}])

    const [isWill, setIsWill] = React.useState(false)
    const [willData, setWillData] = React.useState([{}])

    const [husband, setHusband] = React.useState(false)
    const [husbandName, setHusbandName] = React.useState()
    const [husbandError, setHusbandError] = React.useState()
    const [wife, setWife] = React.useState(false)
    const [numOfWife, setNumOfWife] = React.useState( 1 )
    const [wifeData, setWifeData] = React.useState([{}])


    const [fatherCheck, setFatherCheck] = React.useState(false)
    const [father, setFather] = React.useState()
    const [fatherError, setFatherError] = React.useState( null)

    const [motherCheck, setMotherCheck] = React.useState(false)
    const [mother, setMother] = React.useState()
    const [motherError, setMotherError] = React.useState( null)

    const [isChild, setIsChild] = React.useState(false)
    const [childrenData, setChildrenData] = React.useState([{}])

    const [isSibling, setIsSibling] = React.useState(false)
    const [siblingsData, setSiblingsData] = React.useState([{}])

    const [isNearAndDear, setIsNearAndDear] = React.useState(false)
    const [nearAndDearData, setNearAndDear] = React.useState([{}])

    React.useEffect(() => {
        var refreshAt = (localStorage.getItem('refreshAt'))
        if( !refreshAt){
            let options = {
                url: wakeUpBackend
            }
            HttpService( options).then((response) => {
                console.log("Backend Waked up.")
                localStorage.setItem('refreshAt', new Date())
            }).catch( err => {
                console.log(" Error in health API: ", err)
            })
        } else {
            let currentDate = new Date();
            refreshAt = new Date(refreshAt)
            let Difference = Math.abs(currentDate - refreshAt)
            Difference = Difference /(1000*60)

            if( Difference > 14 ){
                let options = {
                    url: wakeUpBackend
                }
                HttpService( options).then((response) => {
                    console.log("Backend Waked up.")
                    localStorage.setItem('refreshAt', new Date())
                }).catch( err => {
                    console.log(" Error in health API: ", err)
                })
            }
        }

    })

    React.useEffect(() => {
        const oldData = JSON.parse(localStorage.getItem('data'))
        if ( oldData?.name ){
            setName( () => ( oldData.name) )
        }
        if( oldData?.male){
            setMale(() => ( oldData.male))
        }
        if( oldData?.female){
            setFemale(() => ( oldData.female))
        }
        if ( oldData?.totalAmount ){
            setTotalAmount( () => ( oldData.totalAmount) )
        }
        if(oldData?.husband){
            setHusband(() => (oldData.husband))
        }
        if( oldData?.husbandName){
            setHusbandName(() => oldData.husbandName)
        }
        if(oldData?.wife){
            setWife(() => (oldData.wife))
        }
        if( oldData?.wifeData){
            setWifeData(oldData.wifeData)
            setNumOfWife(oldData.wifeData.length)
        }
        if( oldData?.motherCheck){
            setMotherCheck(() => ( oldData.motherCheck))
        }
        if( oldData?.mother){
            setMother(() => ( oldData.mother))
        }
        if( oldData?.fatherCheck){
            setFatherCheck(() => ( oldData.fatherCheck))
        }
        if( oldData?.father){
            setFather(() => ( oldData.father))
        }
        if( oldData?.isChild){
            setIsChild(() => (oldData.isChild))
        }
        if( oldData?.childrenData){
            console.log(" children Data from local storage: ", oldData.childrenData)
            setChildrenData(() => ( oldData.childrenData))
        }
        if( oldData?.isSibling){
            setIsSibling(() => (oldData.isSibling))
        }
        if( oldData?.siblingsData){
            setSiblingsData(() => (oldData.siblingsData))
        }
        if( oldData?.isDebt){
            setIsDebt(() => (oldData.isDebt))
        }
        if( oldData?.debtData){
            setDebtData(() => (oldData.debtData))
        }
        if( oldData?.isWill){
            setIsWill(() => oldData.isWill)
        }
        if( oldData?.willData){
            setWillData(() => (oldData.willData))
        }
        if( oldData?.isNearAndDear){
            setIsNearAndDear(() => oldData.isNearAndDear)
        }
        if( oldData?.nearAndDearData){
            setNearAndDear(() => (oldData.nearAndDearData))
        }
        
    }, [])


    const openSnackbar = (msg, severity) => {
        setOpen(true);
        setText(msg)
        setSeverity(severity)
    };
    
      const closesnackBar = (event, reason) => {
        if ("clickaway" === reason) return;
        setOpen(false);
        setText("")
    };
    
    const updateDebtDetails = async (newDetails, index) => {
        let DebtArray = debtData
        DebtArray[index] = newDetails;
        setDebtData(DebtArray)
    }

    const updateDebtError = (nameMsg, amountMsg, index) => {
        let DebtArray = debtData
        DebtArray[index]['nameError'] = nameMsg
        DebtArray[index]['amountError'] = amountMsg
        setDebtData([...DebtArray])
    }
    
    const addDebt = () => {
        let newArray = [...debtData, {}]
        setDebtData( newArray)
    }

    const deleteDebt = async ( index) => {
        if( debtData.length <= 1){
            setDebtData([{}])
        } else {
            let debtArray = debtData
            debtArray.splice(index, 1)
            setDebtData([...debtArray])
        }
    }

    const updateWillDetails = async (newDetails, index) => {
        let willArray = willData
        willArray[index] = newDetails;
        setWillData( willArray)
    }

    const updateWillError = (nameMsg, amountMsg, relationMsg, index) => {
        let willArray = willData
        willArray[index]['nameError'] = nameMsg
        willArray[index]['amountError'] = amountMsg
        willArray[index]['relationError'] = relationMsg
        setWillData([...willArray])
    }

    const addWiller = () => {
        let newArray = [...willData, {}]
        setWillData( newArray)
    }

    const deleteWill = async ( index) => {
        if( willData.length <= 1){
            setWillData([{}])
        } else {
            let willArray = willData
            willArray.splice(index, 1)
            setWillData([...willArray])
        }
    }


    const updateWifeName = (newDetails, index) => {
        let wifeArray = wifeData
        wifeArray[index] = newDetails;
        setWifeData(wifeArray)
    }

    const updateWifeError = async (msg, index) => {
        let wifeArray = wifeData
        wifeArray[index]['error'] = msg
        setWifeData([...wifeArray])
    }

    const addWife = async () =>{
        if( numOfWife < 4){
            let newArray = [...wifeData, {}]
            setWifeData(newArray)
            await setNumOfWife((prev) => prev + 1 )
        } else {
            console.log(" Number of wife cannot be more than 4.")
        }    
    }

    const deleteWife = async (index) => {
        if ( numOfWife === 1){
            setWifeData([{}])
        } else {
            let wifeArray = wifeData
            wifeArray.splice(index, 1)
            setWifeData([...wifeArray])
            await setNumOfWife( () => numOfWife -1)
        }
    }

    const updateChildDetails = async (newDetails, index) => {
        let childArray = childrenData
        childArray[index] = newDetails;
        setChildrenData(childArray)
    }

    const updateChildError = (nameMsg, radioMsg, index) => {
        let childArray = childrenData
        childArray[index]['nameError'] = nameMsg
        childArray[index]['radioError'] = radioMsg
        setChildrenData( [...childArray])
    }
    
    const addChild = async () => {
        let newArray = childrenData
        newArray = [...childrenData, {}]
        await setChildrenData(newArray)
    }

    const deleteChild = async ( index) => {
        if( childrenData.length <= 1){
            setChildrenData([{}])
        } else{
            let childArray = childrenData
            childArray.splice(index, 1)
            setChildrenData([...childArray])
        }
    }

    const updateSiblingsDetails = async (newDetails, index) => {
        let siblingsArray = siblingsData
        siblingsArray[index] = newDetails;
        setSiblingsData( siblingsArray)
    }

    const updateSiblingError = (nameMsg, radioMsg, index) => {
        let siblingArray = siblingsData
        siblingArray[index]['nameError'] = nameMsg
        siblingArray[index]['radioError'] = radioMsg
        setSiblingsData( [...siblingArray])
    }

    const addSibling = async () => {
        let newArray = [...siblingsData, {}]
        await setSiblingsData( newArray)
    }

    const deleteSibling = async ( index) => {
        if( siblingsData.length <= 1){
            setSiblingsData([{}])
        } else{
            let siblingsArray = siblingsData
            siblingsArray.splice(index, 1)
            setSiblingsData([...siblingsArray])
        }
    }

    const updateNDDetails = async (newDetails, index) => {
        let nDArray = nearAndDearData
        nDArray[index] = newDetails;
        setNearAndDear( nDArray)
    }

    const updateNDError = (radioMsg, nameMsg, relationMsg, index) => {
        let nDArray = nearAndDearData
        nDArray[index]['nameError'] = nameMsg
        nDArray[index]['radioError'] = radioMsg
        nDArray[index]['relationError'] = relationMsg
        setNearAndDear([...nDArray])
    }

    const addND = async () => {
        let newArray = [...nearAndDearData, {}]
        await setNearAndDear( newArray)
    }

    const deleteND = async ( index) => {
        if( nearAndDearData.length <= 1){
            setNearAndDear([{}])
        } else{
            let nDArray = nearAndDearData
            nDArray.splice(index, 1)
            setNearAndDear([...nDArray])
        }
    }

    const handleSubmit = async () => {
        let data = {
            "name": name,
            "male": male,
            "female": female,
            "totalAmount": totalAmount,
            "motherCheck": motherCheck,
            "fatherCheck": fatherCheck,
            "father": father,
            "mother": mother,
            "husband": husband,
            "husbandName": husbandName,
            "wife": wife,
            "wifeData": wifeData,
            "isChild": isChild,
            "childrenData": childrenData,
            "isSibling": isSibling,
            "siblingsData": siblingsData,
            "isDebt": isDebt,
            "debtData": debtData,
            "willData": willData,
            "isWill": isWill,
            "isNearAndDear": isNearAndDear,
            "nearAndDearData": nearAndDearData
        }

        let validationResult = await ValidateSubmit( data)
        console.log(" validation Result: ", validationResult)

        validationResult['issueList'].forEach(( error) => {
            switch( error.field){
                case 'name':
                    setNameError( error['msg'])
                    break;
                case 'gender':
                    setGenderError( error['msg'])
                    break;
                case 'totalAmount':
                    setTotalAmtError( error['msg'])
                    break;
                case 'debt':
                    updateDebtError(error['nameMsg'], error['amountMsg'], error['index'])
                    data['debtData'] = debtData
                    break;
                case 'father':
                    setFatherError( error['msg'])
                    break;
                case 'mother':
                    setMotherError( error['msg'])
                    break;
                case 'husband':
                    setHusbandError( error['msg'])
                    break;
                case 'wife':
                    updateWifeError(error['msg'], error['index'])
                    data['wifeData'] = wifeData
                    break;
                case 'children':
                    updateChildError(error['nameMsg'], error['radioMsg'], error['index'])
                    data['childrenData'] = childrenData
                    break;
                case 'sibling':
                    updateSiblingError(error['nameMsg'], error['radioMsg'], error['index'])
                    data['siblingsData'] = siblingsData
                    break;
                case 'will':
                    updateWillError( error['nameMsg'], error['amountMsg'], error['relationMsg'], error['index'])
                    data['willData'] = willData
                    break;
                case 'nearAndDear':
                    updateNDError( error['radioMsg'], error['nameMsg'], error['relationMsg'], error['index'])
                    data['nearAndDearData'] = nearAndDearData
            }
        })

        if( !(validationResult['issueExists'])){
            setIsLoading( true)
            localStorage.setItem("data", JSON.stringify(data))
            let options ={
                url: calculateShare,
                payload: {}
            }
            options.payload.totalAmount = totalAmount
            
            if( isDebt){
                options.payload.debt = debtData
            }

            if( isWill){
                options.payload.will = willData
            }

            if( husband){
                options.payload.spouse = {"gender": "M", "name": [husbandName]}
            } else {
                if( wife ){
                    options.payload.spouse = {"gender": "F", "name": wifeData}
                }
            }

            if( fatherCheck ){
                options.payload.father = father
            }

            if( motherCheck ){
                options.payload.mother = mother
            }

            if( isChild){
                let childrenPayload = [...childrenData]
                console.log(" childrenData: ", childrenData)
                childrenPayload.map(( child) => {
                    child.opt1 ? 
                    child['gender'] = "M":
                    child['gender'] = "F"
                    delete(child.opt1)
                    delete(child.opt2)
                    if( child['dead']){
                        child['gcDetails'].map((gc) => {
                            gc.opt1 ? 
                            gc['gender'] = "M":
                            gc['gender'] = "F"
                            delete(gc.opt1)
                            delete(gc.opt2)
                        } )
                    }
                })
                options.payload.children = childrenPayload
            }
            
            if(isSibling){
                let siblingsPayload = [...siblingsData]
                siblingsPayload.map((sibling) => {
                    sibling.opt1 ? 
                    sibling['gender'] = "M" :
                    sibling['gender'] = "F"
                    delete(sibling.opt1)
                    delete(sibling.opt2)
                })
                options.payload.siblings = siblingsPayload
            }

            if(isNearAndDear){
                let nDPayload = [...nearAndDearData]
                nDPayload.map((nD) => {
                    nD.opt1 ? 
                    nD['gender'] = "M" :
                    nD['gender'] = "F"
                    delete( nD.opt1)
                    delete(nD.opt2)
                })
                options.payload.nearAndDear = nDPayload
            }
            HttpService( options).then((response) => {
                console.log(" Got the response: ", response.data)
                navigate( "/result", {state: {"response": response.data, "name": name}})
            }).catch( err => {
                setIsLoading( false)
                openSnackbar("Error in calculating Inheritance Distributions.", "Failure")
                console.log(" Got the Error: ", err)
            })
        }
    }

    return(
        <>
        <Header/>
        {
            isLoading ? <Loader></Loader> :
            <div className= {`container-sm`} style={{ margin: "auto", marginTop: "3rem", color: "#2B5BA3", fontSize: "1.1rem", maxWidth: "25rem", overflow:"auto", padding: "1.5rem 1.5rem 2.3rem 1.5em"}}>
             {/* Name and Gender Div */}
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.2rem"}}>
                <strong style={{fontWeight: 500}}>Name*</strong>
                <span>
                    <strong style={{fontWeight: 500, marginLeft: "7.5rem"}}>Gender*</strong>
                </span>
                <div style={{padding: "0.2rem 0.3rem"}}>
                    <input type="text"
                        label="Name*"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                            setNameError( null)
                            setName(e.target.value)
                    }}
                    style={{...inputBoxMediumStyle, width: "40%"}}>
                </input>
                <label style={{marginLeft: "0.5rem"}}> Male</label>
                <input type="radio"
                    checked = {male}
                    onChange={() => {
                        setGenderError( null)
                        setMale(true)
                        setFemale( false)  
                    }}
                    style={{marginLeft: "0.3rem"}}></input>
                <label style={{marginLeft: "0.5rem"}}> Female</label>
                <input type="radio"
                    checked = {female}
                    onChange={() => {
                        setGenderError( null)
                        setMale(false)
                        setFemale( true)
                    }}
                    style={{marginLeft: "0.3rem"}}></input>
                </div>
                <div style={{...errorStyle, marginLeft: "0.4rem", display: "flex", justifyContent: "space-between"}}>
                    { nameError ? nameError : ""}
                    <span style={{float: "right", marginRight: "3.5rem"}}>
                        { genderError ? genderError : ""}
                    </span>
                </div>
            </div>

            {/* Amount Div */}
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Total Amount*</strong>
                <div style={{padding: "0.2rem 0.3rem"}}>
                <input type="Number"
                    value={totalAmount}
                    placeholder="Amount"
                    onChange={(e) => {
                        setTotalAmtError( null)
                        setTotalAmount ( e.target.value)
                        // const numericValue = parseFloat(e.target.value.replace(/[^\d.]/g, ''));
                        // console.log(" numeric Value : ", numericValue)
                        // // Check if numericValue is a valid number
                        // if (!isNaN(numericValue)) {
                        //     console.log(" inside if")
                        //     // Format the number as Indian Rupees
                        //     const formattedAmount = new Intl.NumberFormat('en-IN', {
                        //     style: 'currency',
                        //     currency: 'INR',
                        //     }).format(numericValue);

                        //     console.log(" formatted Amount: ", formattedAmount)

                        //     // Update the state with the formatted value
                        //     setTotalAmount(formattedAmount);
                        // } else {
                        //     console.log(" Inside else")
                        //     // If the input is not a valid number, set the state to an empty string
                        //     setTotalAmount('');
                        // }
                    }}
                    style={{...inputBoxStyle, width: "90%"}}>

                </input>
                <div style={{...errorStyle, marginLeft: "0.4rem"}}>
                    { totalAmtError ? totalAmtError : ""}
                </div>
                </div>
            </div>

            {/* Debt Div */}
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Debt</strong>
                <input type="checkbox"
                    checked={ isDebt} 
                    onChange={() => {
                        setIsDebt(!isDebt)
                    }}
                    style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                </input>
                { isDebt ? 
                    <>
                    <img src={require("../assets/images/add.png")} style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}} onClick={async () => {
                                addDebt()
                            }}></img>
                    <div>
                        {debtData.map((element, index) => {
                            return(
                                <DebtComponent value={element} index={index} key={'d' + index} updateFunc={updateDebtDetails} deleteFunc={deleteDebt} isDebt={isDebt}></DebtComponent>
                            )
                        })}
                    </div>
                    </>: 
                    <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                        {debtData.map((element, index) => {
                            return(
                                <DebtComponent value={element} index={index} key={'d' + index} updateFunc={updateDebtDetails} deleteFunc={deleteDebt} isDebt={isDebt}></DebtComponent>
                            )
                        })}
                    </div>}
            </div>

            {/* Willer Div */}
            <div style = {{ padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Will</strong>
                
                <input type="checkbox"
                    checked={ isWill} 
                    onChange={() => {
                        setIsWill(!isWill)
                    }}
                    style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                </input>

                {isWill ? 
                    <>
                    <img src={require("../assets/images/add.png")} style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}} onClick={() => {
                            addWiller()
                        }}></img>
                    <div >
                    {
                        willData.map(( element, index) => {
                            return(
                                <WillComponent index={index} key={index} value={element} updateFunc={updateWillDetails} deleteFunc={deleteWill} isWill={isWill}></WillComponent>
                            )
                        })
                    }
                    </div>
                    </>: 
                    <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                    {
                        willData.map(( element, index) => {
                            return(
                                <WillComponent index={index} key={index} value={element} updateFunc={updateWillDetails} deleteFunc={deleteWill} isWill={isWill}></WillComponent>
                            )
                        })
                    }
                    </div>
                 }
            </div>

            {/* spouse Div */}
                {male ? 
                    <>
                    <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                    <strong style={{fontWeight: 500}}>Wife</strong>
                        <input type="checkbox"
                            checked={ wife} 
                            onChange={() => {
                                setWife( !wife)
                                setHusband( false)
                            }}
                            style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                        </input>
                        { wife ?
                        <>
                        { (numOfWife < 4) ? 
                                <img src={require("../assets/images/add.png")} 
                                style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}}
                                onClick={() => {
                                    addWife()
                                }}></img>
                                : ""
                                }
                        <div>
                                { wifeData.map( (element, index) => {
                                    return(
                                        <SpouseComp value={element} index={index} key={index} updateFunc={updateWifeName} delFunc={deleteWife} ></SpouseComp>
                                    )

                                })}
                            </div>
                        </>  : 
                            <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                                { wifeData.map( (element, index) => {
                                    return(
                                        <SpouseComp value={element} index={index} key={index} updateFunc={updateWifeName} delFunc={deleteWife} ></SpouseComp>
                                    )

                                })}
                            </div>
                        }
                        </div>
                    </>
                    :
                    female ? 
                    <>
                    <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                        <strong style={{fontWeight: 500}}>Husband</strong>
                        <input type="checkbox" 
                            checked={husband}
                            onChange={() => {
                                setHusband(!husband)
                                setWife(false)
                            }}
                            style={{marginLeft: "0.5rem", width: "1.1rem", height: "1.1rem"}}>
                        </input>
                        { husband ? 
                            <div style={{ padding: "0.2rem 0.1rem"}}>
                                <input type="text" placeholder="Name"
                                    value={husbandName}
                                    onChange={(e) => {
                                        setHusbandName(e.target.value)
                                    }}
                                    style={{...inputBoxMediumStyle, marginLeft: "6rem"}}
                                    >
                                </input>
                                <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                                    { husbandError ? husbandError : ""}
                                </div>
                            </div> : 
                            <div style={{ padding: "0.2rem 0.1rem", opacity: 0.5,pointerEvents: 'none'}}>
                                <input type="text" placeholder="Name"
                                    value={husbandName}
                                    onChange={(e) => {
                                        setHusbandName(e.target.value)
                                    }}
                                    style={{...inputBoxMediumStyle, marginLeft: "6rem"}}
                                    >
                                </input>
                                <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                                    { husbandError ? husbandError : ""}
                                </div>
                            </div>
                        }
                        </div>
                    </>:
                    ""
                }


            {/* Parents Div */}
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Parents</strong>
                <div style={{ padding: "0.2rem 0rem"}}>
                    <label style={{marginLeft: "0.5rem"}}>Father</label>
                    <input type="checkbox"
                        checked={fatherCheck}
                        onChange={() => {
                            if( fatherCheck === true){
                                setFatherCheck(false)
                            } else {
                                setFatherCheck(true)
                            }
                        }}
                        style={{marginLeft: "1rem", width: "1.1rem", height: "1.1rem"}}>
                    </input>
                    { fatherCheck ? 
                    <span style = {{}}>
                        <input type="text" placeholder="Name"
                            value={father}
                            onChange={(e) => {
                                setFather(e.target.value)
                            }}
                            style={{...inputBoxMediumStyle, marginLeft: "1rem"}}
                            >
                        </input>
                        <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                            { fatherError ? fatherError : ""}
                        </div>
                    </span>:
                    <span style={{opacity: 0.5,pointerEvents: 'none',}}>
                        <input type="text" placeholder="Name"
                            value={father}
                            onChange={(e) => {
                                setFather(e.target.value)
                            }}
                            style={{...inputBoxMediumStyle, marginLeft: "1rem"}}
                            >
                        </input>
                        <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                            { fatherError ? fatherError : ""}
                        </div>
                    </span> }
                </div>
                <div style={{padding: "0.2rem 0rem"}}>
                    <label style={{marginLeft: "0.5rem"}}>Mother</label>
                    <input type="checkbox"
                        checked={motherCheck}
                        onChange={() => {
                            if( motherCheck === true){
                                setMotherCheck(false)
                            } else {
                                setMotherCheck(true)
                            }
                        }}
                        style={{marginLeft: "0.6rem", width: "1.1rem", height: "1.1rem"}}
                    ></input>
                    { motherCheck ? 
                        <span>
                            <input type="text" placeholder="Name"
                                disabled = { !motherCheck}
                                value={mother}
                                onChange={(e) => {
                                    setMother(e.target.value)
                                }}
                                style={{...inputBoxMediumStyle, marginLeft: "1rem"}}
                            >
                            </input>
                            <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                                { motherError ? motherError : ""}
                            </div>
                        </span> :
                        <span style={{opacity: 0.5,pointerEvents: 'none',}}>
                            <input type="text" placeholder="Name"
                                disabled = { !motherCheck}
                                value={mother}
                                onChange={(e) => {
                                    setMother(e.target.value)
                                }}
                                style={{...inputBoxMediumStyle, marginLeft: "1rem"}}
                            >
                            </input>
                            <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                                { motherError ? motherError : ""}
                            </div>

                        </span>
                    }
                </div>
            </div>

            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Children</strong>
                <input type="checkbox"
                    checked={ isChild} 
                    onChange={() => {
                        setIsChild(!isChild)
                    }}
                    style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                </input>
                {
                    isChild ? 
                    <>
                    <img src={require("../assets/images/add.png")} style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}}
                        onClick={() => {
                            addChild()
                        }}>
                        </img>
                    <div>
                        {
                            childrenData.map(( element, index) => {
                                return(
                                    <ChildComponent key={'c' + index} index={index} opt1='Son' opt2='Daughter' value={element} updateFunc={updateChildDetails} deleteFunc={deleteChild} isExists= {isChild}></ChildComponent>
                                )
                            })
                        }
                    </div>
                    </>:
                    <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                        {
                            childrenData.map(( element, index) => {
                                return(
                                    <ChildComponent key={'c' + index} index={index} opt1='Son' opt2='Daughter' value={element} updateFunc={updateChildDetails} deleteFunc={deleteChild} isExists= {isChild}></ChildComponent>
                                )
                            })
                        }
                    </div>
                }
            </div>
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Siblings</strong>
                <input type="checkbox"
                    checked={ isSibling} 
                    onChange={() => {
                        setIsSibling(!isSibling)
                    }}
                    style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                </input>
                {
                    isSibling ? 
                    <>
                    <img src={require("../assets/images/add.png")} style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}}
                        onClick={() => {
                            addSibling()
                        }}></img>
                    <div>
                        {
                            siblingsData.map(( element, index) => {
                                return(
                                    <RadioNameComp key={'s' + index} index={index} opt1='Brother' opt2='Sister' value={element} updateFunc={updateSiblingsDetails} deleteFunc={deleteSibling} isExists={isSibling}></RadioNameComp>
                                )
                            })
                        }
                    </div>
                    </> :
                    <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                        {
                            siblingsData.map(( element, index) => {
                                return(
                                    <RadioNameComp key={'s' + index} index={index} opt1='Brother' opt2='Sister' value={element} updateFunc={updateSiblingsDetails} deleteFunc={deleteSibling} isExists={isSibling}></RadioNameComp>
                                )
                            })
                        }
                    </div>

                }
                
            </div>

             {/* Near and Dear */}
            <div style = {{padding: "0.5rem", boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)", borderRadius: "0.5rem",  marginTop: "0.5rem"}}>
                <strong style={{fontWeight: 500}}>Near & Dears</strong>
                <input type="checkbox"
                    checked={ isNearAndDear} 
                    onChange={() => {
                        setIsNearAndDear(!isNearAndDear)
                    }}
                    style={{marginLeft: "0.5rem", width: "1.2rem", height: "1.2rem"}}>
                </input>
                {
                    isNearAndDear ? 
                    <>
                    <img src={require("../assets/images/add.png")} style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.3rem"}}
                        onClick={() => {
                            addND()
                        }}></img>
                    <div>
                        {
                            nearAndDearData.map(( element, index) => {
                                return(
                                    <NearAndDearComponent key={'s' + index} index={index} opt1='Brother' opt2='Sister' value={element} updateFunc={updateNDDetails} deleteFunc={deleteND} isExists={isNearAndDear}></NearAndDearComponent>
        
                                )
                            })
                        }
                    </div>
                    </> :
                    <div style={{opacity: 0.5,pointerEvents: 'none'}}>
                        {
                            nearAndDearData.map(( element, index) => {
                                return(
                                    <NearAndDearComponent key={'s' + index} index={index} opt1='Brother' opt2='Sister' value={element} updateFunc={updateNDDetails} deleteFunc={deleteND} isExists={isNearAndDear}></NearAndDearComponent>
                                )
                            })
                        }
                    </div>

                }
                
            </div>

            <div style={{textAlign: "center", marginTop: "1rem"}}>
                <button
                    onClick={ async() => {
                        localStorage.clear()
                        setName( () => (""))
                        setMale( () => (false))
                        setFemale( () => false)
                        setTotalAmount(() => (0))
                        setMotherCheck( () => false)
                        setFatherCheck( () =>false)
                        setFather(() => (""))
                        setMother(() =>(""))
                        setHusband(() => false)
                        setWife(() => false)
                        setWifeData(() => [{}])
                        setNumOfWife(() => 1)
                        setIsChild(() => false)
                        setChildrenData(() => [{}])
                        setIsSibling(() => false)
                        setSiblingsData(() => [{}])
                        setIsDebt(() => false)
                        setDebtData(() => [{}])
                        setWillData(() => ([{}]))
                        setIsWill(() => false)
                        setIsNearAndDear(() => false)
                        setNearAndDear(() => ([{}]))
                        }   
                    }
                    style={{backgroundColor: "#737373", padding: "0.4rem", width: "6rem", color: "white", fontSize: "1rem", borderRadius: "0.5rem", border: "2px solid"}}
                > Reset
                </button>
                <button 
                    onClick={handleSubmit}
                    style={{backgroundColor: "#2B5BA3", padding: "0.4rem", width: "6rem", color: "white", fontSize: "1rem", borderRadius: "0.5rem", border: "2px solid"}}> 
                    Submit
                </button>
                
            </div>
            
        </div>
        }
        
        {snackbarOpen ? (
        <CustomSnackbar showSnackbar = {snackbarOpen} msg = {snackBarText} severity = {snackbarSeverity}  handleToClose = {() => {closesnackBar()}}></CustomSnackbar>
        ): null}

        </>
    )
}

export default MainCard