const validateName = (input) => {
    let specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    if( specialChars.test( input)){
        return (' Special character not allowed except _')
    }
    return ""
}

const validateGC = (gcList) => {
    console.log(" yahan tak aa gya .......")
    let respArray = []
    let issueExist = false
    gcList.forEach((ele, index) => {
        let resp={
            radioMsg: "",
            nameMsg: "",
            index: index
        }
        if( !ele.opt1 && !ele.opt2){
            resp['radioMsg'] = "Please choose child gender."
            issueExist = true
        }
        if( !ele.name || ele.name === ""){
            resp['nameMsg'] = "Name cannot be Blank."
            issueExist = true
        }
        else {
            let nameResult = validateName( ele.name)
                if(!( nameResult === "")){
                    resp['nameMsg'] = nameResult
                    issueExist = true
                }
        }
        respArray.push(resp)
    })
    return {respArray: respArray, issueExist: issueExist}
}

const validateTotalAmt = ( input) => {
    if( !input || input == ""){
        return (" Total Amount cannot be Blank.")
    } else if( parseInt(input) === 0){
        return ("Amount should be greater than 0.")

    }
    return ""
}

const validateDebt = ( debtArray , emptyReq = true) => {
    let respObj = {}
    if( debtArray.length === 0 && emptyReq){
        respObj['missingValue'] = "both"
        return( respObj)
    }
    debtArray.forEach(element => {
        if(!element['name']){
            respObj['missingValue'] = "name"
        }
        else if( ! element['amount'])
            respObj['missingValue'] = "amount"
    });
    return ( respObj)
}

// const validateWill = ( willArray , emptyReq = true) => {
//     let respObj = {}
//     if( willArray.length === 0 && emptyReq){
//         respObj['missingValue'] = "all"
//         return( respObj)
//     }
//     debtArray.forEach(element => {
//         if(!element['name']){
//             respObj[''] = "name"
//         }
//         else if( ! element['amount'])
//             respObj['missingValue'] = "amount"
//     });
//     return ( respObj)
// }



const ValidateSubmit = async ( data) => {
    return new Promise(async ( resolve, reject) => {
        let issuesList = []
        let issueExists = false
        if( ! data['name'] || data['name'] === ""){
            issuesList.push({field: "name", msg: "Name is required."})
            issueExists = true
        } else {
            let result = await validateName(data['name'])
            if( !(result === "")){
                issuesList.push({field: "name", msg: result})
                issueExists = true
            } else {
                issuesList.push({field: "name", msg: ""})
            }
        }

        if( ! (data['male'] || data['female'])){
            issueExists = true
            issuesList.push({field: "gender", msg: "Please choose either male or female."})
        } else {
            issuesList.push({field: "gender", msg: ""})
        }

        if( ! data['totalAmount'] || data['totalAmount'] === ""){
            issueExists = true
            issuesList.push( {field: "totalAmount", msg: "Total Amount cannot be Blank."})
        } else if( parseInt(data['totalAmount']) === 0){
            issuesList.push( {field: "totalAmount", msg: "Total Amount should be greater than 0."})
            issueExists = true
        } else {
            issuesList.push( {field: "totalAmount", msg: ""})
        }

        if( data['isDebt']){
            data['debtData'].forEach((ele, index) => {
                let resp = {
                    field: "debt",
                    nameMsg: "",
                    amountmsg: "",
                    index: index
                }
                if( !(ele.amount) || ele.amount == ""){
                    resp['amountMsg'] = "Amount cannot be Blank."
                    issueExists = true
                }
                if(!(ele.name) || ele.name === ""){
                    issueExists = true
                    resp['nameMsg'] = "Name cannot be Blank."
                } else {
                    let nameResult = validateName( ele.name)
                    if(!( nameResult === "")){
                        issueExists = true
                        resp['nameMsg'] = nameResult
                    }
                }
                issuesList.push(resp)
            })
        }

        if( data['fatherCheck']){
            if( !data['father']){
                issuesList.push({field: "father", msg: "Father Name Cannot be Blank."})
                issueExists = true
            } else {
                let result = await validateName( data['father'])
                if( !(result === "")){
                    issuesList.push({field: "father", msg: result})
                    issueExists = true
                } else {
                    issuesList.push({field: "father", msg: ""})
                }

            }
            
        }

        if( data['motherCheck']){
            if( !data['mother']){
                issuesList.push({field: "mother", msg: "Mother Name Cannot be Blank."})
                issueExists = true
            } else {
                let result = await validateName( data['mother'])
                if( !(result === "")){
                    issuesList.push({field: "mother", msg: result})
                    issueExists = true
                } else {
                    issuesList.push({field: "mother", msg: ""})
                }

            }
            
        }

        if( data['husband']){
            if( !data['husbandName']){
                issuesList.push({field: "husband", msg: "Husband Name Cannot be Blank."})
                issueExists = true
            } else {
                let result = await validateName( data['husbandName'])
                if( !(result === "")){
                    issuesList.push({field: "husband", msg: result})
                    issueExists = true
                } else {
                    issuesList.push({field: "husband", msg: ""})   
                }
            }
        }

        if( data['wife']){
            await data['wifeData'].forEach( async (ele, index) => {
                let error = ""
                if( !ele || ele['name'] === ""){
                    error = "Wife Name cannot be Blank."
                    issueExists = true
                } else {
                    let result = await validateName( ele['name'])
                    if( !( result === "")){
                        error = result
                        issueExists = true
                    }
                }
                issuesList.push({field: "wife", msg: error, index: index})
            })
        }

        if( data['isChild']){
            data['childrenData'].forEach((ele, index) => {
                let resp = {
                    field: "children",
                    nameMsg: "",
                    radioMsg: "",
                    index: index
                }
                if( !ele.opt1 && !ele.opt2){
                    resp['radioMsg'] = "Please choose child gender."
                    issueExists = true
                }
                if ( !ele.name || ele.name === ""){
                    resp['nameMsg'] = "Name cannot be Blank."
                    issueExists = true
                } 
                else {
                    let nameResult = validateName( ele.name)
                        if(!( nameResult === "")){
                            resp['nameMsg'] = nameResult
                            issueExists = true
                        }
                }
                issuesList.push(resp)
            })
        }

        if( data['isSibling']){
            data['siblingsData'].forEach((ele, index) => {
                let resp = {
                    field: "sibling",
                    nameMsg: "",
                    radioMsg: "",
                    index: index
                }
                if( !ele.opt1 && !ele.opt2){
                    resp['radioMsg'] = "Please choose sibling gender."
                    issueExists = true
                }
                if ( !ele.name || ele.name === ""){
                    resp['nameMsg'] = "Name cannot be Blank."
                    issueExists = true
                } 
                else {
                    let nameResult = validateName( ele.name)
                        if(!( nameResult === "")){
                            resp['nameMsg'] = nameResult
                            issueExists = true
                        }
                }
                issuesList.push(resp)
            })
        }

        if( data['isWill']){
            data['willData'].forEach(( ele, index) => {
                let resp = {
                    field: "will",
                    amountMsg: "",
                    nameMsg: "",
                    relationMsg: "",
                    index: index
                }
                if( !(ele.amount) || ele.amount == ""){
                    resp['amountMsg'] = "Amount cannot be Blank."
                    issueExists = true
                }
                if(!(ele.name) || ele.name === ""){
                    resp['nameMsg'] = "Name cannot be Blank."
                    issueExists = true
                } else {
                    let nameResult = validateName( ele.name)
                    if(!( nameResult === "")){
                        resp['nameMsg'] = nameResult
                        issueExists = true
                    }
                }
                if(!(ele.relation) || ele.relation === ""){
                    resp['relationMsg'] = "Relation cannot be Blank."
                    issueExists = true
                } else {
                    let relationResult = validateName( ele.relation)
                    if(!( relationResult === "")){
                        resp['relationMsg'] = relationResult
                        issueExists = true
                    }
                }
                issuesList.push(resp)
            })
        }

        if( data['isNearAndDear']){
            data['nearAndDearData'].forEach(( ele, index) => {
                let resp = {
                    field: "nearAndDear",
                    radioMsg: "",
                    nameMsg: "",
                    relationMsg: "",
                    index: index
                }
                if( !ele.opt1 && !ele.opt2){
                    resp['radioMsg'] = "Please choose gender."
                    issueExists = true
                }
                if(!(ele.name) || ele.name === ""){
                    resp['nameMsg'] = "Name cannot be Blank."
                    issueExists = true
                } else {
                    let nameResult = validateName( ele.name)
                    if(!( nameResult === "")){
                        resp['nameMsg'] = nameResult
                        issueExists = true
                    }
                }
                if(!(ele.relation) || ele.relation === ""){
                    resp['relationMsg'] = "Relation cannot be Blank."
                    issueExists = true
                } else {
                    let relationResult = validateName( ele.relation)
                    if(!( relationResult === "")){
                        resp['relationMsg'] = relationResult
                        issueExists = true
                    }
                }
                issuesList.push(resp)
            })
        }


        // console.log(" Issue List : ", issuesList)
        resolve( {issueList: issuesList, issueExists: issueExists})
    })
}

export {
    ValidateSubmit,
    validateGC
}