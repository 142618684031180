import React from "react"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const GCDetails = ( props) => {
    let inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "32%",
        padding: "0rem 0.3rem"
    }

    let errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        width: "90%"
        // marginTop: "-0.45rem"
    }

    const [radio1, setRadio1] = React.useState(props.value?.opt1)
    const [radio2, setRadio2] = React.useState(props.value?.opt2)
    const [childName, setchildName] = React.useState(props.value?.name)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [radioError, setRadioError] = React.useState(props.value?.radioError)

    let errorMargin = "2.5rem"
    if( !(radioError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setRadio1(() => props.value?.opt1 ? props.value.opt1 : false)
        setRadio2(() => props.value?.opt2 ? props.value.opt2 : false )
        setchildName(() => props.value?.name ? props.value.name: "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setRadioError(() => props.value?.radioError ? props.value?.radioError : false )
        // setIsExists(() => props.isExists ? props.isExists : false)
    }, [props.value?.opt1, props.value?.opt2, props.value?.name, props.value?.nameError, props.value?.radioError, props.isExists])

    React.useEffect(() => {
        props.updateFunc(
            {
                'opt1': radio1,
                'opt2': radio2,
                'name': childName
            }, props.index
        )
    }, [radio1, radio2, childName])

    const handleDropDown = (event) => {
        if( event.target.value == 'son'){
            setRadio1(() => true)
            setRadio2(() => false)
        } else {
            setRadio1(() => false)
            setRadio2(() => true)
        }
    }

    const handleChange = async (event) => {
        await setchildName( event.target.value)
    }

    const handleDelete = async () => {
        props.deleteFunc( props.index)
    }
    
    return(
        <>
            <div style={{  padding: "0.9rem 0.1rem 0rem 0rem"}}>

            {/* <label style={{marginLeft: "0.2rem"}}>Son</label>
            <input type="radio" 
                checked = {radio1}
                onChange={ () => {
                    setRadio1(true)
                    setRadio2(false)
                }} style={{marginLeft: "0.1rem"}}>
            </input>

            <label style={{marginLeft: "0.4rem"}}>Daughter</label>
            <input type="radio"
                checked= {radio2}
                onChange={() => {
                    setRadio1(false)
                    setRadio2(true)
                }}
                style={{marginLeft: "0.1rem"}}>
            </input> */}

            <FormControl sx={{m:0.9}} size="small" >
            <InputLabel id="demo-simple-select-label" style={{ marginTop: "-0.4rem"}}>Relation</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={radio1 ? 'son' : radio2 ? 'daughter' : null}
                label="Gender"
                // onChange={handleChange}
                style={{ width: "6.2rem", height: "1.6rem"}}
                onChange={handleDropDown}
            >
                <MenuItem value={'son'}>Son</MenuItem>
                <MenuItem value={'daughter'}>Daughter</MenuItem>
            </Select>
            </FormControl>

            <input type="text" placeholder="Name" 
                onChange={handleChange}
                value={childName}
                style={{...inputBoxMediumStyle, marginTop: "0.5rem", marginLeft: "0.1rem"}}></input>

            <img src={require("../assets/images/delete_icon.png")}
                        style={{marginLeft: "0.2rem", marginBottom: "-0.5rem"}}
                        onClick={() => {
                            handleDelete()
                        }}
                    >
            </img>
            </div>
            <div style={{...errorStyle, marginLeft: "0.5rem"}}>
                { radioError ? radioError : ""}
                <span style={{marginLeft: errorMargin}}>
                    { nameError ? nameError : ""}
                </span>
            </div>
        </>
    )
}
export default GCDetails