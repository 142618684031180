import React from "react"

const SpouseComp = ( props) => {
    let inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "40%",
        padding: "0rem 0.3rem"
    }

    let errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        // marginTop: "-0.45rem"
    }
    const [wifeName, setWifeName] = React.useState(props.value?.name)
    const [wifeError, setWifeError] = React.useState( props.value?.error)

    React.useEffect(() => {
        setWifeName(() =>  props.value?.name ? props.value.name: "")
        setWifeError(() => props.value?.error ? props.value.error: "")
    },[props.value?.name, props.value?.error])

    React.useEffect(() => {
        props.updateFunc(
            {
                'name': wifeName,
                'error': wifeError
            }, props.index
            )
    }, [wifeName])

    const handleChange = async ( event) => {
        await setWifeName(
            event.target.value
            )
    }

    const handleDelete = async () => {
        props.delFunc( props.index)
    }
    
    return(
        <>
            <div style={{padding: "0.2rem 0.1rem"}}>
            <input type="text" placeholder="Name"
                onChange={handleChange}
                value={wifeName}
                style={{...inputBoxMediumStyle, marginLeft: "6rem"}}></input>
            <img src={require("../assets/images/delete_icon.png")}
                style={{marginLeft: "0.2rem", marginBottom: "-0.5rem"}}
                onClick={() => {
                    handleDelete()
                }}
            >
            </img>
            </div>
            <div style={{...errorStyle, marginLeft: "6.5rem"}}>
                { wifeError ? wifeError : ""}
            </div>
        </>
    )
}
export default SpouseComp