import React from "react"

const WillComponent = ( props) => {
    let inputBoxSmallStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "27%",
        padding: "0rem 0.3rem"
    }

    let errorStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "0.1rem",
        color: "red",
        fontSize: "0.65rem",
        width: "85%"
        // marginTop: "-0.45rem"
    }

    const [name, setName] = React.useState(props.value?.name)
    const [relation, setRelation] = React.useState(props.value?.relation)
    const [amount, setAmount ] = React.useState(props.value?.amount)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [relationError, setRelationError] = React.useState(props.value?.relationError)
    const [amountError, setAmountError ] = React.useState(props.value?.amountError)
    const [isWill, setIsWill] = React.useState(props.isWill ? props.isWill : false)

    let errorMargin = "0.2rem"
    if( !(amountError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setName(() => props.value?.name ? props.value?.name : "")
        setRelation(() => props.value?.relation ? props.value?.relation : "")
        setAmount(() => props.value?.amount ? props.value?.amount : "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setRelationError(() => props.value?.relationError ? props.value?.relationError : "")
        setAmountError(() => props.value?.amountError ? props.value?.amountError : "")
        setIsWill(() => (props.isWill ? props.isWill : false))
    }, [props.value?.name, props.value?.relation, props.value?.amount, props.value?.nameError, props.value?.amountError, props.value?.relationError, props.isWill])

    React.useEffect(() => {
        props.updateFunc({
            'name': name,
            'relation': relation,
            'amount': amount
        }, props.index
        )
    }, [name, relation, amount])

    const handleNameChange = ( event) => {
        setName( event.target.value)
    }

    const handleRelationChange = ( event) => {
        setRelation( event.target.value)
    }

    const handleAmountChange = ( event) => {
        setAmount( event.target.value)
    }

    const handleDelete = () => {
        props.deleteFunc( props.index)
    }

    return(
        <>
        <div style={{ display: "flex", justifyContent: "space-between", width: "90%", padding: "0.2rem 0.3rem"}}>
            <input type="Number" placeholder="Amount"
            disabled={!isWill}
            value={amount}
            onChange={ handleAmountChange}
             style={inputBoxSmallStyle}></input>
            <input type="text" placeholder="Name" 
                disabled={!isWill}
                value={name}
                onChange={handleNameChange}
            style={inputBoxSmallStyle}></input>
            <input type="text" placeholder="Relation"
                disabled={!isWill}
                value={relation}
                onChange={handleRelationChange}
             style={inputBoxSmallStyle}></input>
             <img src={require("../assets/images/delete_icon.png")}
                    style={{}}
                    onClick={() => {
                        handleDelete()
                    }}
                >
        </img>
        </div>
            <div style={{...errorStyle, marginLeft: "0.3rem"}}>
                <div>
                    {amountError ? amountError : ""}
                </div>
                <div>
                    {nameError ? nameError : ""}
                </div>
                <div>
                    {relationError ? relationError : ""}
                </div>
        </div>
        </>
    )
}

export default WillComponent