import { useNavigate } from "react-router-dom"
import Header from "./header"

const LandPage = () => {
    const navigate = useNavigate()
    return(
        <>
        <Header/>
            <div style = {{marginTop: "4.2rem", textAlign: "center", display: 'flex', justifyContent: "center", padding: "0.2rem", overflow: "auto"}}>
                <div style={{maxWidth: "50rem", width: "90%"}}>
                    <div style={{maxWidth: "50rem", width:"100%"}}>
                        <img 
                            src={require("../assets/images/WORK latest logo.png")}
                            style={{ width: "10rem"}}
                            onClick={() => {
                                window.open("https://allamasyedabdullahtariq.com/", "_blank")
                            }}
                        >
                        </img>
                    </div>

                        <p style={{color: "#2B5BA3", fontSize: "1rem", fontWeight: "bold"}}>
                            The aim of this App is to help people to 
                            calculate the correct inheritance shares 
                            according to Quran And Hadees
                        </p>

                        <h1 style={{fontSize: "1.7rem", color: "#f1c276", textShadow: "1px 1px 2px rgba(192, 192, 192, 1)"}}> 
                            Click to Calculate Inheritance
                        </h1>

                        <button 
                            onClick={() => {
                                navigate("/calculator")
                            }}
                            style={{marginTop: "-0.5rem", backgroundColor: "#2B5BA3", padding: "0.4rem", width: "12rem", color: "white", fontSize: "1.5rem", borderRadius: "0.5rem", border: "2px solid"}}> 
                            Click Here
                        </button>
    
                    <div style={{marginTop: "1.3rem"}}>
                        <img src={require("../assets/images/youtube_thumbnail.png")} style={{width: "100%"}}
                            onClick={() => {
                                window.open("https://www.youtube.com/watch?v=yoe0huUwkdg&list=PLTPwffe8uEJuUgLi-EgHs-zyV6jFnjoec&pp=iAQB")
                            }}
                        >
                        </img>
                        
                    </div>

                    <div style={{ display: "flex", justifyContent: "center"}}>
                    <div style={{ textAlign: "left", maxWidth: "50rem", width: "100%", marginTop: '1rem', backgroundColor: "#2B5BA3",boxShadow: "2px 2px 3px 2px rgba(237, 200, 138, 2)", border: "0.5px solid #2B5BA3", borderRadius: "0.7rem", padding: '0.6rem', color: "white"}}>
                        <strong>Do you want to know more?</strong>
                        <div>
                            <ul>
                                <li 
                                    style={{marginLeft: "-1rem", marginTop: "-0.7rem"}}
                                    onClick={() => {
                                        window.open("https://allamasyedabdullahtariq.com/quranic-terminologies/", "_blank")
                                    }}
                                >
                                     Quran Terminology (English)</li>
                                <li
                                    style={{marginLeft: "-1rem"}}
                                    onClick={() => {
                                        window.open("https://allamasyedabdullahtariq.com/quranic-terminologies-hindi/", "_blank")
                                    }}
                                > Quran Terminology (Hindi)</li>
                                <li
                                    style={{marginLeft: "-1rem"}}
                                    onClick={() => {
                                        window.open("https://allamasyedabdullahtariq.com/answers-by-allama/", "_blank")
                                    }}
                                > Answers by Allama</li>
                                <li
                                    style={{marginLeft: "-1rem"}}
                                    onClick={() => {
                                        window.open("https://allamasyedabdullahtariq.com/compassion-day/", "_blank")
                                    }}
                                > Compassion Day</li>
                            </ul>
                        </div>

                    </div>
                    </div>

                    <div style={{height: "5rem"}}>

                    </div>
                </div>

            </div>
        </>
    )
}

export default LandPage