import React from "react"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BotModal from "./dialogBox";
import { Button, FormGroup } from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { validateGC } from "../validations/homePageValidations";
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const NearAndDearComponent = ( props) => {
    let inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "20%",
        padding: "0rem 0.3rem",

    }

    let errorStyle = {
        display: "grid",
        gridTemplateColumns: "1.2fr 1fr 1fr",
        gap: "0.1rem",
        color: "red",
        fontSize: "0.65rem",
        width: "75%"
        // marginTop: "-0.45rem"
    }

    const [radio1, setRadio1] = React.useState(props.value?.opt1)
    const [radio2, setRadio2] = React.useState(props.value?.opt2)
    const [name, setName] = React.useState(props.value?.name)
    const [relation, setRelation] = React.useState(props.value?.relation)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [radioError, setRadioError] = React.useState(props.value?.radioError)
    const [relationError, setRelationError] = React.useState(props.value?.relationError)

    let errorMargin = "2.5rem"
    if( !(radioError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setRadio1(() => props.value?.opt1 ? props.value.opt1 : false)
        setRadio2(() => props.value?.opt2 ? props.value.opt2 : false )
        setName(() => props.value?.name ? props.value.name: "")
        setRelation(() => props.value?.relation ? props.value.relation: "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setRelationError(() => props.value?.relationError ? props.value?.relationError : "")
        setRadioError(() => props.value?.radioError ? props.value?.radioError : false )
        // setIsExists(() => props.isExists ? props.isExists : false)
    }, [props.value?.opt1, props.value?.opt2, props.value?.name, props.value?.nameError, props.value?.relationError, props.value?.radioError, props.isExists])

    React.useEffect(() => {
        props.updateFunc(
            {
                'opt1': radio1,
                'opt2': radio2,
                'name': name,
                'relation': relation
            }, props.index
        )
    }, [radio1, radio2, name, relation])


    const  handleNameChange = async (event) => {
        await setName( event.target.value)
    }

    const handleRelationChange = async (event) => {
        await setRelation( event.target.value)
    }

    const handleDelete = async () => {
        props.deleteFunc( props.index)
    }

    const handleDropDown = (event) => {
        
        if( event.target.value == 'Male'){
            setRadio1(() => true)
            setRadio2(() => false)
        } else {
            setRadio1(() => false)
            setRadio2(() => true)
        }
    }
    
    return(
        <>
            <div style={{ padding: "0.2rem 0rem"}}>
            <FormControl sx={{m:1}} size="small" >
            <InputLabel style={{ marginTop: "-0.4rem"}}>Gender</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={radio1 ? 'Male' : radio2 ? 'Female' : ""}
                label="Gender"
                // onChange={handleChange}
                style={{ width: "6.2rem", height: "1.6rem"}}
                onChange={handleDropDown}
            >
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
            </Select>
            </FormControl>

            <input type="text" placeholder="Name" 
                onChange={handleNameChange}
                value={name}
                style={{...inputBoxMediumStyle, marginLeft: "0.2rem", marginTop: "0.5rem"}}></input>

            <input type="text" placeholder="Relation" 
                onChange={handleRelationChange}
                value={relation}
                style={{...inputBoxMediumStyle, marginLeft: "0.2rem", marginTop: "0.5rem"}}></input>

            <img src={require("../assets/images/delete_icon.png")}
                        style={{marginLeft: "0.1rem", marginBottom: "-0.5rem"}}
                        onClick={() => {
                            handleDelete()
                        }}
                    >
            </img>
            </div>
            <div style={{...errorStyle, marginLeft: "0.5rem"}}>
                {/* { radioError ? radioError : ""}
                <span style={{marginLeft: errorMargin}}>
                    { nameError ? nameError : ""}
                </span> */}
                <div>
                    {radioError ? radioError : ""}
                </div>
                <div>
                    {nameError ? nameError : ""}
                </div>
                <div>
                    {relationError ? relationError : ""}
                </div>
            </div>
        </>
    )
}
export default NearAndDearComponent