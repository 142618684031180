import axios from "axios";
import { baseURL } from "./backendAPIs";

const HttpService = ( options) => {
    let url = baseURL + options.url
    console.log(" URL: ", url)
    return axios( url, {
        method: options.method ? options.method.toUpperCase() : "POST",
        params: options.params ? options.params : null,
        data: options.payload
    })
}

export default HttpService;