export default function Header( props ){
    return(
        <>
        <nav className="navbar navbar-expand-lg" style={{backgroundColor: "#2B5BA3", position: "fixed", top: "0rem", width: "100%"}}>
        {/* <img src={require("../assets/images/WORK latest logo.png")} style={{border: "3px solid white", backgroundColor: "white", height: "2.2rem", marginTop: "0.2rem", marginLeft: "0.6rem", float: "left"}}></img> */}
        <div className="container-fluid" style={{display: "flex", justifyContent: "center", flexWrap: "nowrap"}}>
            <a className="navbar-brand mx-auto" style={{fontSize: "1.6rem", color: "white", textAlign: "center", padding: "0.5rem"}}>Inheritance Calculator</a>
        </div>
        </nav>
        </>
    )
}