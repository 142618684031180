import React from 'react'
// import { MDBFooter } from 'mdb-react-ui-kit';

const Footer = () => {
  let footerStyle = {
    
    position: "fixed",
    bottom: "0rem",
    width: "100%",
    backgroundColor: "#2B5BA3",
    padding:"0.3rem",
    textAlign: "center",
    color: "white",
    fontSize: "1rem",
    fontFamily: "PlayFair Display",
    fontWeight: 400
  }
  return (
    <div style= {footerStyle}>
      All Rights Reserved.
    </div>
  )
}

export default Footer
