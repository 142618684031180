import React from "react"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import BotModal from "./dialogBox";
import { Button, FormGroup } from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CustomSwitch from './customSwitch'
import { validateGC } from "../validations/homePageValidations";
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ChildComponent = ( props) => {
    let inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "22%",
        padding: "0rem 0.3rem",
        marginBottom: "0.2rem"

    }

    let errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        width: "90%"
        // marginTop: "-0.45rem"
    }

    const [radio1, setRadio1] = React.useState(props.value?.opt1)
    const [radio2, setRadio2] = React.useState(props.value?.opt2)
    const [childName, setchildName] = React.useState(props.value?.name)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [radioError, setRadioError] = React.useState(props.value?.radioError)
    const [isExists, setIsExists] = React.useState(props.isExists ? props.isExists : false)
    const [dead, setDead] = React.useState(props.value.dead ? props.value.dead : false)
    const [gcDetails, setGCDetails] = React.useState(props.gcDetails ? props.gcDetails: [{}])
    const [isDialogOpen, setIsDialogOpen] = React.useState( false)

    let errorMargin = "2.5rem"
    if( !(radioError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setRadio1(() => props.value?.opt1 ? props.value.opt1 : false)
        setRadio2(() => props.value?.opt2 ? props.value.opt2 : false )
        setchildName(() => props.value?.name ? props.value.name: "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setRadioError(() => props.value?.radioError ? props.value?.radioError : false )
        setIsExists(() => props.isExists ? props.isExists : false)
        setDead(() => props.value.dead ? props.value.dead : false)
        setGCDetails(() => props.value.gcDetails ? props.value.gcDetails : [{}])
    }, [props.value?.opt1, props.value?.opt2, props.value?.name, props.value?.nameError, props.value?.radioError, props.isExists, props.dead,props.gcDetails])

    React.useEffect(() => {
        props.updateFunc(
            {
                'opt1': radio1,
                'opt2': radio2,
                'name': childName,
                'dead': dead,
                'gcDetails': gcDetails
            }, props.index
        )
    }, [radio1, radio2, childName, dead, gcDetails])

    const addGC = () => {
        var newArray = [...gcDetails, {}]
        setGCDetails( newArray)
    }

    const updateGCDetails = (newGCDetails, index) => {
        var oldGCDetails = gcDetails
        oldGCDetails[index] = newGCDetails
        setGCDetails([...oldGCDetails])     
    }

    const deleteGC = (index) => {
        if( gcDetails.length <= 1){
            setGCDetails([{}])
        } else {
            let gcArray = gcDetails
            gcArray.splice( index, 1)
            setGCDetails([...gcArray])
        }
    }

    const validateGCDetails = async () => {
        let validationResult = await validateGC(gcDetails)
        console.log(" validation Result: ", validationResult)
        validationResult['respArray'].forEach((ele, index) => {
            let gcArray = gcDetails
            gcArray[index]['radioError'] = ele['radioMsg']
            gcArray[index]['nameError'] = ele['nameMsg']
            setGCDetails([...gcArray])
        })
        return validationResult['issueExist']
    }

    const resetGC = () => {
        setGCDetails([{}])
    }

    const cancelGC = () => {
        setGCDetails([{}])
        setDead(() => false)

    }

    const handleDialogChange = (value) => {
        setIsDialogOpen(value)
    }

    const handleChange = async (event) => {
        await setchildName( event.target.value)
    }

    const handleDelete = async () => {
        props.deleteFunc( props.index)
    }

    const handleDropDown = (event) => {
        
        if( event.target.value == 'son'){
            setRadio1(() => true)
            setRadio2(() => false)
        } else {
            setRadio1(() => false)
            setRadio2(() => true)
        }
    }
    
    return(
        <>
            <div style={{ padding: "0.2rem 0rem", display: "flex", alignItems: 'center'}}>
            <FormControl sx={{m:0.8}} size="small"  >
            <InputLabel id="demo-simple-select-label" style={{ marginTop: "-0.2rem"}}>Relation</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={radio1 ? 'son' : radio2 ? 'daughter' : ""}
                label="Gender"
                // onChange={handleChange}
                style={{ width: "6.3rem", height: "1.6rem", marginTop: '0.3rem'}}
                onChange={handleDropDown}
            >
                <MenuItem value={'son'}>Son</MenuItem>
                <MenuItem value={'daughter'}>Daughter</MenuItem>
            </Select>
            </FormControl>

            {/* <label style={{marginLeft: "0.5rem"}}>{props.opt1}</label> */}
            {/* <input type="radio" 
                disabled = {!isExists}
                checked = {radio1}
                onChange={ () => {
                    setRadio1(true)
                    setRadio2(false)
                }} style={{marginLeft: "0.3rem"}}>
            </input> */}

            {/* <label style={{marginLeft: "0.7rem"}}>{props.opt2}</label>
            <input type="radio"
                disabled = {!isExists}
                checked= {radio2}
                onChange={() => {
                    setRadio1(false)
                    setRadio2(true)
                }}
                style={{marginLeft: "0.2rem"}}>
            </input> */}

            <input type="text" placeholder="Name" 
                disabled = {!isExists}
                onChange={handleChange}
                value={childName}
                style={{...inputBoxMediumStyle, marginLeft: "0.3rem", marginTop: "0.5rem"}}></input>
            
            {/* <input type="checkbox"
                // checked={ isDebt} 
                // onChange={() => {
                //     setIsDebt(!isDebt)
                // }}
                style={{marginLeft: "0.5rem", width: "1.1rem", height: "1.1rem", marginTop: "0.5rem"}}>
            </input>
            <label> Is Dead</label> */}

            <span>
                <CustomSwitch checked = {dead} onClick={() =>{
                    if (dead){
                        setDead(() => false)
                    } else {
                        setDead(() => true)
                        handleDialogChange(true)
                    }
                }}>
                </CustomSwitch>
            </span>

            {/* <span style={{ marginLeft: "0.2rem"}}>
                {dead ? 
                    <Button style={{ maxWidth: "1rem", height: "1.75rem", backgroundColor: "red", color: "white"}} onClick={() => {
                        setDead(() => false)
                    }}>Dead</Button>:
                    <Button style={{ height: "1.75rem", backgroundColor: "#2B5BA3", color: "white"}} onClick={() => {
                        setDead(() => true)
                        handleDialogChange(true)
                    }}>Alive</Button>
                }

            </span> */}

            
                {/* <span style={{display: 'flex', flexDirection: "column", fontSize: "0.8rem"}}>
                    IS Dead?
                    <Switch checked={dead} style={{size: "0.5rem"}}>
                    </Switch>
                </span>                 */}
                {/* <div>
                    
                </div> */}
                {/* <FormControlLabel control={
                <Switch />} 
                /> */}
                {/* <FormGroup>
                
                </FormGroup> */}


            { dead ?
                <img src={require("../assets/images/edit_icon_small.png")}
                        style={{ height: "1rem", padding: "0.2rem", marginLeft: "0.2rem", marginBottom: "-0.4rem", border: "1.5px solid", borderRadius: "0.2rem"}}
                        onClick={() => {
                            setIsDialogOpen(() => true)
                        }}
                        >
                </img> : ""
            }

            <img src={require("../assets/images/delete_icon.png")}
                        style={{marginLeft: "0.1rem", marginBottom: "-0.5rem"}}
                        onClick={() => {
                            handleDelete()
                        }}
                    >
            </img>
            </div>
            <div style={{...errorStyle, marginLeft: "0.5rem"}}>
                { radioError ? radioError : ""}
                <span style={{marginLeft: errorMargin}}>
                    { nameError ? nameError : ""}
                </span>
            </div>
            {
                isDialogOpen ? 
                <BotModal handleDialogChange={handleDialogChange} dead={dead} gcDetails={gcDetails} addGC = {addGC} updateFunc={updateGCDetails} deleteFunc={deleteGC} validateFunc={validateGCDetails} resetFunc={resetGC} cancelFunc = {cancelGC}></BotModal> : ""
            }
        </>
    )
}
export default ChildComponent