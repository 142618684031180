import { Link, useLocation, useNavigate } from "react-router-dom"
import Header from "./header"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React from "react"

const ResultPage = ( props ) => {
    const navigate = useNavigate()
    const location = useLocation()
    const data = location?.state?.response
    const name = location?.state?.name
    let dataArray = data?.description
    let totalAmount = 0
    totalAmount += (dataArray?.['father']?.['amount']) ? parseFloat(dataArray['father']['amount']) : 0
    totalAmount += (dataArray?.['mother']?.['amount']) ? parseFloat(dataArray['mother']['amount']) : 0
    let thStyle = {
        backgroundColor: "#2B5BA3",
        color: "white",
        // width: "23rem"
    }
    let tableStyle={
        textAlign: "center",
        maxWidth: "25rem",
        width: "100%",
        border: "1px solid", 
        marginTop: "2rem",
    }
    let tdStyle= {
        borderBottom: "2px solid #D3D3D3"
    }

    const downloadPDF = () => {
        const input = document.getElementById("table-elem")
        html2canvas(input, {width: 512, scale:2}).then((canvas) => {
            const imgData = canvas.toDataURL("image/png")
            const pdf = new jsPDF({orientation: 'p', unit: 'mm', format: "a4"});
            const imgProps = pdf.getImageProperties( imgData)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = ( imgProps.height * pdfWidth) / (imgProps.width)
            pdf.addImage(imgData, "JPEG", 22, 25, pdfWidth, pdfHeight)
            pdf.save(`${name}.pdf`)
        })
    }

    React.useEffect(() => {
        if(!data){
            console.log(" Inside If ")
            navigate("/")
        }
    }, [])

    return(
        <>
            <Header/>
            {dataArray ?
        <div style = {{marginTop: "2rem", textAlign: "center", display: 'flex', justifyContent: "center", padding: "0.2rem"}}>
            <div style= {{width: "90%",maxWidth: "25rem", textAlign: "center", overflow: "auto"}}>
                {/* <div style = {{textAlign: "center"}}> */}
                    {
                        dataArray['msg'] ? 
                            <div style= {{marginTop: "2.5rem", fontWeight: 400, fontSize: "2rem", color: "#2B5BA3"}}>
                            {dataArray['msg']}
                            </div>
                            :
                            <div>
                    <table id="table-elem" style={tableStyle}>
                        <tr>
                        <td colSpan="3" >
                                {name}
                            </td>
                        </tr>

                        <tr>
                            <th style = { thStyle}>Name</th>
                            <th style = {thStyle}>Relation</th>
                            <th style = {thStyle}>Amount</th>
                        </tr>
                        {dataArray['debt'] ? 
                            dataArray['debt'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                <td style={tdStyle}> {ele.name}</td>
                                <td style={tdStyle}> {ele.relation ? ele.relation : "-"}  </td>
                                <td style={tdStyle}> {ele.amount}</td>
                                </tr>
                                )
                            }): ""
                        }

                        {dataArray['willerDistribution'] ? 
                            dataArray['willerDistribution'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                <td style={tdStyle}> {ele.name}</td>
                                <td style={tdStyle}> {ele.relation}</td>
                                <td style={tdStyle}> {ele.amount}</td>
                                </tr>
                                )
                            }): ""
                        }

                        {dataArray['spouse'] ? 
                            dataArray['spouse'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                <td style={tdStyle}> {ele.name.name ? ele.name.name : ele.name}</td>
                                <td style={tdStyle}> {ele.relation}</td>
                                <td style={tdStyle}> {ele.amount}</td>
                                </tr>
                                )
                            }): ""
                        }

                        {dataArray['father'] ? 
                            <tr>
                            <td style={tdStyle}> {dataArray['father']['name']}</td>
                            <td style={tdStyle}> Father</td>
                            <td style={tdStyle}> {dataArray['father']['amount']}</td>
                            </tr>
                        : ""
                        }

                        {dataArray['mother'] ? 
                            <tr>
                            <td style={tdStyle}> {dataArray['mother']['name']}</td>
                            <td style={tdStyle}> Mother</td>
                            <td style={tdStyle}> {dataArray['mother']['amount']}</td>
                            </tr>
                        : ""
                        }

                        {dataArray['childrenDistribution'] ?
                            dataArray['childrenDistribution'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                <td style={tdStyle}> {ele.name}</td>
                                <td style={tdStyle}> {ele.relation}</td>
                                <td style={tdStyle}> {ele.amount}</td>
                                </tr>
                                )
                            }): ""
                        }

                        {dataArray['siblingsDistribution'] ?
                            dataArray['siblingsDistribution'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                <td style={tdStyle}> {ele.name}</td>
                                <td style={tdStyle}> {ele.relation}</td>
                                <td style={tdStyle}> {ele.amount}</td>
                                </tr>
                                )
                            }): ""
                        }

                        {dataArray['nearAndDear'] ? 
                            dataArray['nearAndDear'].map((ele) => {
                                totalAmount += parseFloat(ele.amount)
                                return(
                                    <tr>
                                        <td style={tdStyle}>{ele.name}</td>
                                        <td style={tdStyle}>{ele.relation}</td>
                                        <td style={tdStyle}>{ele.amount}</td>
                                    </tr>
                                )
                            })
                        :""
                        }

                        <tr>
                            <td colSpan="2" style={{borderTop: "1px solid"}}>
                                Total Amount
                            </td>
                            <td style={{borderTop: "1px solid"}}> {totalAmount.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="2" style={{borderTop: "1px solid"}}>
                                Amount Left
                            </td>
                            <td style={{borderTop: "1px solid"}}> {dataArray['amountLeft']}</td>
                        </tr>
                    </table>
                    </div>
                    }
                {/* </div> */}
            {dataArray['msg'] ? "" :
                <div>
                    <button
                        onClick={() => {
                            downloadPDF()
                        }}
                        style={{marginTop: "0.5rem", backgroundColor: "#2B5BA3", padding: "0.4rem", width: "9rem", color: "white", fontSize: "1rem", borderRadius: "0.5rem", border: "2px solid"}}> 
                        Download PDF
                    </button>
                </div>
            }
        <div style={{ marginTop: '1rem', backgroundColor: "#2B5BA3",boxShadow: "2px 2px 3px 2px rgba(50, 134, 125, 0.2)", border: "0.5px solid #2B5BA3", borderRadius: "0.7rem", padding: '0.6rem', color: "white"}}>
            Do you know except this Inheritance Calculator all the Calculators developed or someone performs the calculations there is a shortage of around 20%  in most of the cases, this is because there is a mistake in understanding QURAN Aayat<br></br>
        <div style={{marginTop: "0.3rem"}}>
            <strong>Do you want to know the correct interpretation?</strong>
        </div> 
        <div>
        <button
            onClick={() =>{
                window.open("https://allamasyedabdullahtariq.com/objection-on-the-law-of-inheritance-answered-by-syed-abdullah-tariq/", "_blank");
            }}
            style={{marginTop: "0.3rem", backgroundColor: "#white", padding: "0.4rem", width: "9rem", color: "#2B5BA3", fontSize: "1rem", borderRadius: "0.5rem", border: "2px solid"}}
            > CLICK ME
        </button>
        </div>
        </div>
        {/* <a href="https://allamasyedabdullahtariq.com/objection-on-the-law-of-inheritance-answered-by-syed-abdullah-tariq/" target="_blank">CLICK ME</a> */}
            </div>
        </div>:
        // <div style={{height: "5rem"}}>
        // </div>
            ""
        }
        </> 
        
    )
    
}

export default ResultPage