import './App.css';
import {
  BrowserRouter, Routes, Route
} from "react-router-dom"
import Footer from './components/footer';
import LandPage from './components/landingPage'
import MainCard from './components/homePage';
import ResultPage from './components/resultPage';

function App() {
  return (
    <>
    <div>
      <BrowserRouter>
      <Routes>
        <Route path = "/" element = {< LandPage />}></Route>
        <Route path = "/calculator" element = {< MainCard/>}></Route>
        <Route exact path = "/result" element = {<ResultPage/>}></Route>
      </Routes>
      <Footer></Footer>
      </BrowserRouter>
    </div>
    </>
  );
}

export default App;
