import React from "react"

const RadioNameComp = ( props) => {
    let inputBoxMediumStyle = { 
        backgroundColor: "#D3D3D3",
        border: "2px #D3D3D3",
        borderRadius: "0.2rem",
        height: "1.7rem",
        width: "28%",
        padding: "0rem 0.3rem"
    }

    let errorStyle = {
        color: "red",
        fontSize: "0.65rem",
        width: "90%"
        // marginTop: "-0.45rem"
    }

    const [radio1, setRadio1] = React.useState(props.value?.opt1)
    const [radio2, setRadio2] = React.useState(props.value?.opt2)
    const [childName, setchildName] = React.useState(props.value?.name)
    const [nameError, setNameError] = React.useState(props.value?.nameError)
    const [radioError, setRadioError] = React.useState(props.value?.radioError)
    const [isExists, setIsExists] = React.useState(props.isExists ? props.isExists : false)

    let errorMargin = "2.5rem"
    if( !(radioError)){
        errorMargin  ="10.5rem"
    }

    React.useEffect(() => {
        setRadio1(() => props.value?.opt1 ? props.value.opt1 : false)
        setRadio2(() => props.value?.opt2 ? props.value.opt2 : false )
        setchildName(() => props.value?.name ? props.value.name: "")
        setNameError(() => props.value?.nameError ? props.value?.nameError : "")
        setRadioError(() => props.value?.radioError ? props.value?.radioError : false )
        setIsExists(() => props.isExists ? props.isExists : false)
    }, [props.value?.opt1, props.value?.opt2, props.value?.name, props.value?.nameError, props.value?.radioError, props.isExists])

    React.useEffect(() => {
        props.updateFunc(
            {
                'opt1': radio1,
                'opt2': radio2,
                'name': childName
            }, props.index
        )
    }, [radio1, radio2, childName])

    const handleChange = async (event) => {
        await setchildName( event.target.value)
    }

    const handleDelete = async () => {
        props.deleteFunc( props.index)
    }
    
    return(
        <>
            <div style={{  padding: "0.2rem 0.1rem"}}>

            <label style={{marginLeft: "0.5rem"}}>{props.opt1}</label>
            <input type="radio" 
                disabled = {!isExists}
                checked = {radio1}
                onChange={ () => {
                    setRadio1(true)
                    setRadio2(false)
                }} style={{marginLeft: "0.3rem"}}>
            </input>

            <label style={{marginLeft: "0.7rem"}}>{props.opt2}</label>
            <input type="radio"
                disabled = {!isExists}
                checked= {radio2}
                onChange={() => {
                    setRadio1(false)
                    setRadio2(true)
                }}
                style={{marginLeft: "0.2rem"}}>
            </input>

            <input type="text" placeholder="Name" 
                disabled = {!isExists}
                onChange={handleChange}
                value={childName}
                style={{...inputBoxMediumStyle, marginLeft: "0.6rem"}}></input>

            <img src={require("../assets/images/delete_icon.png")}
                        style={{marginLeft: "0.2rem", marginBottom: "-0.5rem"}}
                        onClick={() => {
                            handleDelete()
                        }}
                    >
            </img>
            </div>
            <div style={{...errorStyle, marginLeft: "0.5rem"}}>
                { radioError ? radioError : ""}
                <span style={{marginLeft: errorMargin}}>
                    { nameError ? nameError : ""}
                </span>
            </div>
        </>
    )
}
export default RadioNameComp