// import React from 'react'
// import { Modal } from 'react-bootstrap'

// export default function BotModal( props) {
//   const [isModalOpen, setModal] = React.useState(props.dead)
//   // const initModal = ( value ) => {
//   //   return setModal(value)
//   // }

//   // React.useEffect(() => {
//   //   initModal(props.dead)
//   // })

//   const customStyles = {
//     position: "fixed",
//     top: '40%',
//     left: '37%',
//     width:"25%",
//     align:"center",
//     // content: {
//     //   // position: "fixed",
//     //   // top: '50%',
//     //   // left: '50%',
//     //   // right: 'auto',
//     //   // bottom: 'auto',
//     //   // marginRight: '-50%',
//     //   // transform: 'translate(10%, 20%)',
//     // },
//   };

//   return (
//     <>
//     {/* <img src={require("../assets/images/add.png")} alt='cont button' onClick={initModal} style={{ position:"absolute", bottom:"3.5rem", right:"1.5rem", marginRight:"0.5rem", width:"48px", height: "48px"}}></img> */}
//       <Modal show={isModalOpen} style={customStyles}>
//         <Modal.Header style={{ width:"105%", height: "45px", color: "white", backgroundColor:"#2B5BA3"}} >
//           <Modal.Title style={{ fontSize: "1.3rem"}}> Children Details</Modal.Title>
//           <button type="button" class="btn-close btn-close-white" aria-label="Close" onClick={() => {setModal(false) }} style={{float: "right"}} ></button>
//         </Modal.Header>
//         <Modal.Body style={{ fontSize: "0.9rem", textAlign: "center", fontFamily: "PlayFair Display", fontWeight: 400}}>
//           <div>
// 					yahan banana hae components ko 
// 					{/* <div style={{marginTop: "7px"}}>
// 					<a href="tel:+918810572569"><img src={require("../assets/images/phone-call.png")} style={{ marginRight: "20px", width: "32px", height: "32px"}}></img></a>
// 					<img src={require("../assets/images/email.png")} onClick = {() => { sendQuery( "Email")}} style={{ marginRight: "20px", width: "32px", height: "32px"}}></img>
// 					<img src={require("../assets/images/whatsapp.png")} onClick = {() => { sendQuery( "WhatsApp")}} style={{ marginRight: "20px", width: "32px", height: "32px"}}></img>
//           </div> */}
// 					</div>
//         </Modal.Body>
//       </Modal>
//     </>
//   )
// }





import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import GCDetails from './grandChildDetailsComponent';

export default function BotModal(props) {
  const [open, setOpen] = React.useState(props.dead);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOK = async () => {
    let issueExist = await props.validateFunc()
    console.log(" issue Exists: ", issueExist)
    if( !issueExist){
      setOpen( false)
      props.handleDialogChange(false)
    }
  }

  const handleReset = () => {
    props.resetFunc()
  }

  const handleCancel = async () => {
    await props.cancelFunc()
    setOpen( false)
    await props.handleDialogChange(false)
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleDialogChange(false)
  };

  const [gcDetails, setGCDetails] = React.useState(props.gcDetails? props.gcDetails : [])



  return (
    <React.Fragment >
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <DialogTitle id="responsive-dialog-title" style={{background: "#2B5BA3", color: "white", height: "0.5rem"}}>
          {/* {"Grand Children Details"} */}
          <div style={{ marginLeft: "-0.75rem", marginTop: "-0.6rem"}}>
            Grand Children Details
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.gcDetails.map((ele, index) => {
              return(
                <GCDetails key={index} index={index} value={ele} updateFunc={props.updateFunc} deleteFunc={props.deleteFunc}></GCDetails>
              )
            })}
            <img src={require("../assets/images/add.png")} style={{ float: "right", marginTop: "-2.5rem"}} onClick={async () => {
                props.addGC()
            }}></img>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button style={{color: "red"}} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
          <Button onClick={handleOK} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}