import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 71,
  height: 26,
  padding: 0.5,
  marginTop: '0.5rem',
  marginLeft: '0.4rem',
  display: 'flex',

  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    position: 'absolute',
    top: '2.5px',
    left: '3px',
    color: 'white'
  },

  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(45px)',
    '& + .MuiSwitch-track': {
      opacity: 1.5,
      backgroundColor: '#2B5BA3',
      '&:after':{
        content: "'Dead'",
        color: 'white',
        marginLeft: "9px"
      }
    },
  },

  '& .MuiSwitch-track': {
    borderRadius: 13,
    opacity: 0.9,
    backgroundColor: '#D3D3D3',
    '&:after':{
      content: "'Dead'",
      color: 'grey',
      marginLeft: "25px",
      fontSize: "0.9rem"
    }
  },
}));

export default CustomSwitch;
