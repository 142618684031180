import React from 'react'

import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
// import { amber, green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { SnackbarContent } from '@material-ui/core';

const style1 = { backgroundColor: "#2E2239", color:"white"}
const style2 = { backgroundColor: "#01579b", color:"white"}
const style3 = { backgroundColor: "#D22B2B", color:"white"}
var style = {}

function setStyle( severity ){
    switch( severity ){
        case 'Success' : style = style1 
        break;
        case 'Info' : style = style2 
        break;
        case 'Failure' : style = style3 
        break;
        default : {}
    }
}
function CustomSnackbar( props ) {
    const {open, msg} = props
    setStyle( props.severity )
  return (
    <div>
      <Snackbar 
        anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
        }}
        open={ props.showSnackbar }
        autoHideDuration={5000}
        onClose={ props.handleToClose}
        
        >
            <SnackbarContent style={ style }
            message={<span id="client-snackbar">{msg}</span>}
            action={
                <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={props.handleToClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                </React.Fragment>
            }
            >
            </SnackbarContent>
        </Snackbar>
    </div>
  )
}

export default CustomSnackbar